<template>
    <div>
        <Table
            :columns="columns"
            :data="resources"
            size="small"
            :show-summary="true"
            :summary-method="handleSummary"
            stripe
        >
            <template slot-scope="{ row }" slot="account_number">
                {{ row.account ? row.account.account_number : "" }}
            </template>
            <template slot-scope="{ row }" slot="account_name">
                {{ row.account ? row.account.account_name_en : "" }}
                {{
                    row.account.account_name_kh
                        ? "|" + row.account.account_name_kh
                        : ""
                }}
            </template>
            <template slot-scope="{ row }" slot="debit_amount">
                {{ formatCurrencyWithCode(row.debit_amount, currency) }}
            </template>
            <template slot-scope="{ row }" slot="credit_amount">
                {{ formatCurrencyWithCode(row.credit_amount, currency) }}
            </template>
        </Table>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { orderBy } from "lodash"
export default {
    props: {
        row: Object
    },
    computed: {
        ...mapGetters(["formatCurrencyWithCode"]),
        resources() {
            return orderBy(this.row.details, ['display_order']);
        },
        currency() {
            return this.row.currency;
        },
        columns() {
            return [
                {
                    title: this.$t("journalEntry.accountNumber"),
                    key: "account_number",
                    slot: "account_number",
                    sortable: true,
                    width: 200
                },
                {
                    title: this.$t("journalEntry.accountName"),
                    key: "account_name",
                    slot: "account_name",
                    sortable: true,
                    width: 350
                },
                {
                    title: this.$t("journalEntry.debitAmount"),
                    key: "debit_amount",
                    slot: "debit_amount",
                    sortable: true,
                    width: 200,
                    align: "right"
                },
                {
                    title: this.$t("journalEntry.creditAmount"),
                    key: "credit_amount",
                    slot: "credit_amount",
                    sortable: true,
                    width: 200,
                    align: "right"
                }
            ];
        }
    },
    methods: {
        handleSummary({ columns, data }) {
            const sums = {};
            columns.forEach((column, index) => {
                const key = column.key;
                if (index === 0) {
                    sums[key] = {
                        key,
                        value: this.$t("journalEntry.total")
                    };
                    return;
                }
                const values = data.map(item => Number(item[key]));
                if (!values.every(value => isNaN(value))) {
                    const v = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[key] = {
                        key,
                        value: this.formatCurrencyWithCode(v, this.currency)
                    };
                } else {
                    sums[key] = {
                        key,
                        value: "N/A"
                    };
                }
            });

            return sums;
        }
    }
};
</script>
